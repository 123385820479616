<template>
  <b-sidebar
    :id="uuid"
    bg-variant="white"
    header-class="darker-sidebar-header"
    left
    backdrop
    shadow
    class="agent-sidebar"
    width="40%"
    lazy
    @hidden="clearInfo"
  >
    <template #header>
      <div class="w-100 d-flex justify-content-between">
        <h2 class="ml-1 mt-50 text-white sidebar-title text-uppercase">{{ $t(`scheduler.sidebar.${i18nKey}.title_add`) }}</h2>

        <b-icon
          icon="x"
          scale="1.8"
          variant="secondary"
          class="cursor-pointer mt-50"
          @click="$emit('close')"
        />
      </div>
    </template>
    <template #default>
      <b-container fluid>
        <b-col md="12" class="mt-2">
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-button size="sm" variant="primary" @click="addItem()">
                + {{ $t(`scheduler.sidebar.${i18nKey}.add_item`) }}
              </b-button>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center" v-for="(item, index) in items" :key="`item-${index}`">
            <b-col md="5">
              <b-form-group
                :label="$t(`scheduler.sidebar.${i18nKey}.key.label`)"
                label-for="webhook-method"
              >
                <b-form-input
                  id="key"
                  :placeholder="$t(`scheduler.sidebar.${i18nKey}.key.placeholder`)"
                  v-model="items[index].key"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t(`scheduler.sidebar.${i18nKey}.value.label`)"
                label-for="webhook-method"
              >
                <b-form-input
                  id="value"
                  :placeholder="$t(`scheduler.sidebar.${i18nKey}.value.placeholder`)"
                  v-model="items[index].value"
                />
              </b-form-group>
            </b-col>
            <b-col md="1" class="mt-2">
              <b-button @click="deleteItem(index)" class="px-1" variant="outline-danger">
                <feather-icon
                  icon="TrashIcon"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-container>
    </template>
    <template #footer>
      <b-row class="mb-75">
        <b-col md="12" class="d-flex justify-content-end">
          <b-button
            variant="primary"
            class="mx-2"
            @click="submit"
            :disabled="isSaving"
          >
            <span v-if="isSaving">
              <b-spinner small></b-spinner>
              <span class="d-inline-block saving-adjust-text-position">
                {{ $t("common.terms.saving") }}
              </span>
            </span>
            <span v-else>{{ $t("common.terms.save") }}</span>
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-sidebar>
</template>

<script>
import { 
  BIcon,
  BButton,
  BContainer,
  BCol,
  BRow,
  BSidebar,
  BFormGroup,
  BSpinner,
  BSkeleton,
  BInputGroup,
  BFormInput,
} from "bootstrap-vue";
import 'animate.css';

  export default {
    components: {
      BIcon,
      BButton, 
      BContainer, 
      BCol, 
      BRow, 
      BSidebar,
      BFormGroup,
      BSpinner,
      BSkeleton,
      BInputGroup,
      BFormInput,
    },
    props: {
      uuid: {
        type: String,
        required: true,
      },
      initialValue: {
        type: Object,
        default: { key: '', value: '' }
      },
      i18nKey: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        isSaving: false,
        items: []
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.addItem()
      },
      deleteItem(index) {
        this.items.splice(index, 1)
      },
      addItem() {
        this.items.push(this.initialValue)
      },
      clearInfo() {
        this.items = []
      },
      submit() {
        this.$root.$emit('bv::toggle::collapse', this.uuid)
        this.$emit('addItems', this.items, this.i18nKey)
      }
    }
  }
</script>

<style lang="scss">

.darker-sidebar-header {
  background-color: #171c29 !important;
}

</style>