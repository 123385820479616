<template>
  <div>
    <b-table
      :items="items"
      :fields="fields"
    >
      <template v-for="field in editableFields" #[`cell(${field.name})`]="{ item, index }">
        <div
          :key="getID(field.key)"
        >
          <b-button 
            v-if="field.name == 'field_value'" 
            pill 
            size="sm" 
            :variant="visible[index] ? 'flat-info' : 'flat-secondary'" 
            class="py-25 px-50 ml-0"
            @click="toggleVisibility(index)"
          >
            <b-icon
              :icon="visible[index] ? 'eye' : 'eye-slash'"
            />
          </b-button>
          {{ field.name != 'field_value' || visible[index] ? item[field.key] : toDots(item[field.key])}}
          <default-changer
            class="d-inline ml-50"
            type="input"
            :i18nKey="i18nKey"
            :field="field.name"
            :action="editAction"
            :aditionalData="{ ...aditionalData, itemID: item.id }"
            v-model="item[field.key]"
          />
          
        </div>
      </template>

      <template #cell(actions)="{ item, index }">
        <b-button class="mr-1" size="sm" variant="danger" @click="$emit('deleteItem', i18nKey, item, index)">
          <feather-icon icon="TrashIcon" /> {{ $t("common.terms.delete") }}
        </b-button>
      </template>
    </b-table>

    
  </div>
</template>

<script>
import { BTable, BButton, BIcon } from 'bootstrap-vue'
import DefaultChanger from '@/layouts/components/DefaultChanger.vue'
import { v4 as uuidv4 } from 'uuid'
  export default {
    components: {
      BTable, 
      BButton, 
      BIcon,
      DefaultChanger,
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
      fields: {
        type: Array,
        required: true,
      },
      i18nKey: { //* for internacionalization 
        type: String,
        required: true,
      },
      editableFields: { //* [ { name: '', key: ''} ]
        type: Array,
        default: () => [],
      },
      editAction: {
        type: String,
        required: true,
      },
      aditionalData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return { 
        uuidMap: {},
        visible: []
      }
    },
    methods: {
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      toDots(item) {
        return Array(item.length).fill('• ').join('').toString()
      },
      toggleVisibility(index) {
        this.$set(this.visible, index, !this.visible[index])
      }
    }

  }
</script>

<style lang="scss" scoped>

</style>