<template>
  <b-modal
    :id="uuid"
    :title="$t(`scheduler.modal.version.title`)"
    dialog-class="set-max-width-1100"
    centered
    lazy
    size="lg"
    no-close-on-backdrop
    ok-only
  >
    <b-container fluid>
      <b-row>
        <b-col>
          <label for="versions">Search for a Version</label>
          <v-select
            v-if="versions"
            id="versions"
            v-model="version"
            :options="versions"
            :reduce="(item) => item"
            label="name"
            @input="getSchedulerScriptVersionByID"
          >
            <template #option="{ name, start_date, end_date, id }">
              <div>
                <span class="m-0">{{ name }}</span>
                <b-badge v-if="currentVersion.id == id" class="ml-1 text-secondary" variant="light" pill>
                  {{ $t('scheduler.view.current') }}
                </b-badge>
              </div>
              <small>{{ 'Start: ' + formatDate(start_date) }}</small>
              -
              <small>{{ 'End: ' + formatDate(end_date) || '&#8734;' }}</small>
            </template>
            <template slot="no-options"> {{$t("terminal_tests.terms.no_options")}} </template>
          </v-select>
        </b-col>
      </b-row>

      <b-row v-if="versionInfo && !versionLoading" class="mt-1">
        <b-col>
          <b-card>
            <div class="d-flex justify-content-between">
              <div>
                <h1 class="d-inline-block">
                  {{ versionInfo.name }}
                </h1>
                <default-changer
                  class="d-inline-block ml-50"

                  i18nKey="scheduler.version.name"
                  field="name"
                  action="patchSchedulerVersion"
                  type="input"
                  v-model="versionInfo.name"
                  :aditionalData="{ versionID: versionInfo.id, transmissionID, schedulerID }"
                />
              </div>
              <div>
                <b-button
                  class="mx-50"
                  title="View Body"
                  pill
                  size="sm"
                  variant="outline-info"
                  @click="viewInfo('body')"
                  >
                    <b-icon icon="file-earmark-text"/>
                    <span class="font-weight-bolder">
                      {{ $t('scheduler.view.view_body') }}
                    </span>
                </b-button>
                <b-button
                  class="mx-50"
                  title="View Script"
                  pill
                  size="sm"
                  variant="outline-success"
                  @click="viewInfo('script')"
                  >
                    <b-icon icon="file-earmark-code"/>
                    <span class="font-weight-bolder">
                      {{ $t('scheduler.view.view_script') }}
                    </span>
                </b-button>
                <script-viewer
                  :uuid="getID('viewer')"
                  :script="viewerInfo"
                  :i18nKey="vieweri18nKey"
                />
                <b-button
                  title="Delete this version"
                  :disabled="loadingDelete"
                  pill
                  class="delete-button mx-50"
                  size="sm"
                  variant="outline-danger"
                  @click="deleteVersion"
                  >
                    <b-icon v-if="!loadingDelete" icon="trash"/>
                    <b-spinner v-else variant="light" small />
                    <span class="font-weight-bolder">
                      {{ $t('common.terms.delete') }}
                    </span>
                </b-button>
              </div>
            </div>
            <div>
              <b-badge
                class="text-uppercase mr-50 badge-toggler py-50"
                :variant="versionInfo.active ? 'success' : 'secondary'"
                >
                <b-icon
                  :icon="versionInfo.active ? 'stop-circle' : 'play-circle'"
                />
                {{ versionInfo.active ? $t('scheduler.view.active') : $t('scheduler.view.inactive') }}
              </b-badge>
              
              <b-badge
                class="text-uppercase mr-50 badge-toggler py-50"
                :variant="versionInfo.repeatable ? 'light-success' : 'light-danger'"
                >
                <b-icon
                  :icon="versionInfo.repeatable ? 'arrow-repeat' : 'x'"
                />
                {{ versionInfo.repeatable ? $t('scheduler.view.repeatable') : $t('scheduler.view.repeatable') }}
              </b-badge>
            </div>

            <b-row class="mt-2">    
              <b-col md="4">
                  <p class="p-0 text-muted small m-0">{{ $t('scheduler.view.start_date') }}</p>
                  <strong>
                    {{ formatDate(versionInfo.start_date) }}
                  </strong>
                </b-col>
                <b-col md="4">
                  <p class="p-0 text-muted small m-0">{{ $t('scheduler.view.end_date') }}</p>
                  <strong>
                    {{ formatDate(versionInfo.end_date) || '&#8734;' }}
                  </strong>
                </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col md="4">
                <p class="p-0 text-muted small m-0">{{ $t('scheduler.view.last_execution_at') }}</p>
                <strong>
                  {{ versionInfo.last_execution_at || $t('scheduler.view.not_executed_yet') }}
                </strong>
              </b-col>
              <b-col md="4">
                <p class="p-0 text-muted small m-0">{{ $t('common.terms.created_at') }}</p>
                <strong>
                  {{ formatDate(versionInfo.created_at) }}
                </strong>
              </b-col>
              <b-col md="4">
                <p class="p-0 text-muted small m-0">{{ $t('common.terms.updated_at') }}</p>
                <strong>
                  {{ formatDate(versionInfo.updated_at) }}
                </strong>
              </b-col>
            </b-row>
            <hr class="my-2">
            <b-row >
              <b-col md="4" v-if="versionInfo.scheduler.path">
                <p class="p-0 text-muted smalmethodl m-0">{{ $t('scheduler.view.path') }}</p>
                <strong class="p-0">
                  {{ versionInfo.scheduler.path }}
                </strong>
              </b-col>
              <b-col md="4">  
                <p class="p-0 text-muted small m-0">{{ $t('scheduler.view.method') }}</p>
                <strong class="p-0">
                  {{ versionInfo.scheduler.enum_metodo.label }}
                </strong>
              </b-col>
              <b-col md="4">  
                <p class="p-0 text-muted small m-0">{{ $t('scheduler.view.interval') }}</p>
                <strong class="p-0">
                  {{ versionInfo.minutes_interval }} {{ $t('scheduler.view.minutes') }}
                </strong>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-skeleton
        class="mt-1"
        v-else
        width="100%"
        height="309.15px"
      />
    </b-container>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BCol, BRow, BSkeleton, BCard, BCardBody, BButton, BBadge, BIcon, BSpinner } from 'bootstrap-vue'
import VSelect from 'vue-select'
import moment from 'moment'
import DefaultChanger from '@/layouts/components/DefaultChanger.vue';
import ScriptViewer from '@/layouts/components/Scheduler/ScriptViewer.vue'
import { v4 as uuidv4 } from 'uuid'
import { mapGetters } from 'vuex'
import { makeToast } from '@/layouts/components/Popups'
  export default {
    components: {
      BModal,
      BContainer, 
      BCol, 
      BRow,
      BSkeleton,
      BCard, 
      BCardBody,
      BButton,
      BBadge,
      BIcon, 
      BSpinner,
      VSelect,
      DefaultChanger,
      ScriptViewer
    },
    props: {
      uuid: {
        type: String,
        required: true
      },
      schedulerID: {
        type: [ Number, String ],
        required: true
      },
      transmissionID: {
        type: [ Number, String ],
        required: true
      },
      currentVersion: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        uuidMap: {},
        versionLoading: false,
        loadingDelete: false,

        versionInfo: null,
        version: null,

        viewerInfo: null,
        vieweri18nKey: null,
      }
    },
    computed: {
      ...mapGetters(['getScheduleVersions']),
      versions() {
        return this.getScheduleVersions(this.transmissionID, this.schedulerID)
      },
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.version = this.currentVersion
        this.$nextTick(() => {
            this.getSchedulerScriptVersionByID()
        })
      },
      viewInfo(type) {
        switch (type) {
          case 'body': {
            this.viewerInfo = this.versionInfo.scheduler.body
            this.vieweri18nKey = type
          } break;
          case 'script': {
            this.viewerInfo = this.versionInfo.script
            this.vieweri18nKey = type
          } break;
        }
        this.$nextTick(() => {
          this.$bvModal.show(this.getID('viewer'))
        })
      },
      deleteVersion() {
        const i18n_base = "scheduler.modal.version.confirm_delete"
       
        if (this.versionInfo.id == this.currentVersion.id ) {
          makeToast({
            title: this.$t(`${i18n_base}.active_error.title`),
            text: this.$t(`${i18n_base}.active_error.message`),
            variant: "danger",
            icon: "XIcon",
          });
          return
        }

        this.$bvModal
          .msgBoxConfirm(
            this.$t(`${i18n_base}.message`, {
              version: this.versionInfo.name,
              schedule: this.versionInfo.scheduler.name,
            }),
            {
              title: this.$t(`${i18n_base}.title`),
              size: "sm",
              okVariant: "primary",
              okTitle: this.$t("common.terms.yes"),
              cancelTitle: this.$t("common.terms.no"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.loadingDelete = true

              this.$store
                .dispatch("deleteScheduleScript", {
                  versionID: this.versionInfo.id,
                  transmissionID: this.transmissionID,
                  schedulerID: this.schedulerID
                })
                .then(() => {
                  this.loadingDelete = false

                  this.versionInfo = null

                  this.$nextTick(() => {
                    this.version = this.currentVersion
                    this.getSchedulerScriptVersionByID()
                    this.$emit('getCurrentVersion')
                  })

                  makeToast({
                    title: this.$t(
                      `${i18n_base}.success.title`
                    ),
                    text: this.$t(
                      `${i18n_base}.success.message`
                    ),
                    variant: "success",
                    icon: "CheckIcon",
                  });
                })
                .catch((error) => {
                  console.log(error)
                  this.loadingDelete = false

                  makeToast({
                    title: this.$t(
                      `${i18n_base}.error.title`
                    ),
                    text: this.$t(
                      `${i18n_base}.error.message`
                    ),
                    variant: "danger",
                    icon: "XIcon",
                  });
                });
            } 
          });
      },
      formatDate(value) {
        if (!value) return null
        let date = new Date(value)
        return moment(date).format(this.$t('scheduler.view.moment_date_format'));
      },
      getSchedulerScriptVersionByID() {
        this.versionLoading = true
        this.$store.dispatch('getSchedulerScriptVersionByID', { versionID: this.version.id }).then((resp) => {

          this.versionInfo = resp.data

          this.versionLoading = false

        }).catch((err) => {
          console.log(err)
          this.versionLoading = false

        })
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
    }
  }
</script>

<style lang="scss">

</style>