<template>
  <b-modal
    :id="uuid"
    :title="$t(`scheduler.modal.${i18nKey}.title`)"
    centered
    @ok.prevent="submit"
  >
  <validation-observer ref="input">
    <validation-provider 
      #default="{ errors }"
      :name="$t(`scheduler.modal.${i18nKey}.input.name`)"
      :rules="required"
    >
      <b-form-group
        :label="$t(`scheduler.modal.${i18nKey}.input.label`)"
        label-for="input"
      >
        <b-form-input
          id="input"
          :placeholder="$t(`scheduler.modal.${i18nKey}.input.placeholder`)"
          v-model="data"
          @keydown.enter="submit"
        />
      </b-form-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </validation-observer>
    
  </b-modal>
</template>

<script>
import { BModal, BFormGroup, BFormInput } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

  export default {
    components: {
      BModal,
      BFormGroup,
      BFormInput,
      ValidationObserver,
      ValidationProvider
    },
    props: {
      uuid: {
        type: String,
        required: true
      },
      value: {
        type: String,
        default: ''
      },
      i18nKey: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        required,
        lastVersion: null
      }
    },
    computed: {
      data: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    },
    mounted() {
      this.lastVersion = this.data
      this.data = this.data ? this.data + '.1' : this.data + 'v0.1'
    },
    methods: {
      submit() {
        this.$refs.input.validate().then((success) => {
          if (success) {
            this.$bvModal.hide(this.uuid)
            this.$emit('submit')
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>